* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.container {
  width: 100%;
  min-height: 100vh;
  background: black;
}

.videoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background: black;
  pointer-events: none;
}

.videoFrame {
  position: absolute;
  right: 50%;
  left: 50%;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
