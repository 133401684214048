.aboutContainer {
  margin-top: 3rem;
  padding-bottom: 4rem;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .aboutContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 80%;
    // border: 1px solid red;
  }
}

.aboutText {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  max-width: 500px;
  flex: 1;
  // margin-right: 3rem;
  text-shadow: 1px 1px 4px black;
  p {
    color: white;
  }
  .title {
    font-size: 35px;
    letter-spacing: 5px;
    margin-bottom: 1.5rem;
  }
  .text {
    font-size: 18px;
  }
}

.aboutImageResponsive {
  display: none;
}
.aboutImage {
  margin-top: 4rem;
  width: 30%;
  img {
    width: 100%;
    border-radius: 12px;
  }
}

.interviewContainer {
  margin-top: 2rem;
  .subtittle {
    font-size: 24px;
    letter-spacing: 2px;
  }
  .interviewContent {
    margin-top: 1.5rem;
    .interviewItem {
      margin-top: 1rem;
      a {
        color: white;
        font-size: 12px;
      }
    }
  }
}

.skillsContainer {
  margin-top: 2rem;
  .subtittle {
    font-size: 24px;
    letter-spacing: 2px;
  }
  .skillsContent {
    margin-top: 1rem;
    .skillsItem {
      margin-top: 0.5rem;
    }
  }
}

.redesContainer {
  margin-top: 2rem;
  .redesTitle {
    font-size: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: white;
  }
  .redesIconContainer {
    margin-top: 1rem;
    a {
      padding: 5px;
      margin-right: 1rem;
      cursor: pointer;
      &:hover {
        .redesIcon {
          color: aquamarine;
        }
      }
    }
    .redesIcon {
      color: white;
      transition: all 0.3s ease;
      font-size: 24px;
    }
  }
  .redesMail {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 5px;
    span {
      color: white;
      transition: all 0.3s ease;
      margin-left: 10px;
    }
    .redesIcon {
      color: white;
      transition: all 0.3s ease;
      font-size: 20px;
    }
  }
}

@media (max-width: 1023px) {
  .aboutContainer {
    margin-top: 3rem;
  }
  .aboutImage {
    display: none;
  }
  .aboutText {
    width: 100%;
    margin-right: 0;
    .title {
      font-size: 35px;
    }
  }

  .aboutText {
    background: rgba(0, 0, 0, 0.7);
  }

  .aboutImageResponsive {
    display: flex;
    margin: 0 auto;
    max-width: 200px;
    margin-bottom: 2rem;
    border-radius: 12px;
    img {
      width: 100%;
    }
  }
}
