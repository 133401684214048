.workContainer {
  margin-top: 3rem;
  padding: 0 0 4rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  .workContent {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    .workItem {
      width: calc((100% / 3) - 2rem);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      border: 1px solid transparent;
      transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      &:hover {
        transform: scale(1.1);
      }
      .item {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        text-decoration: none;
        box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.6);
        display: flex;
        &:hover {
          .titleItem {
            top: 0;
          }
        }
        .titleItem {
          position: absolute;
          background: rgba(0, 0, 0, 0.8);
          width: 100%;
          padding: 1rem;
          font-size: 18px;
          color: white;
          top: -100%;
          transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          z-index: 1;
        }
        img {
          width: 100%;
          transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }
    }
  }
  .workContentImg {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    .workItemImg {
      width: calc((100% / 4) - 2rem);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      border: 1px solid transparent;
      transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      &:hover {
        transform: scale(1.1);
      }
      .item {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        text-decoration: none;
        box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.6);
        display: flex;
        &:hover {
          .titleItem {
            top: 0;
          }
        }
        .titleItem {
          position: absolute;
          background: rgba(0, 0, 0, 0.8);
          width: 100%;
          padding: 1rem;
          font-size: 18px;
          color: white;
          top: -100%;
          transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          z-index: 1;
        }
        img {
          width: 100%;
          // height: 100%;
          transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }
    }
  }
}

.workText {
  margin-bottom: 2rem;
  .title {
    color: white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 5px;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 4px black;
  }
}

@media (max-width: 1400px) {
  .workContainer {
    .workContent {
      iframe {
        height: 200px !important;
      }
    }
    .workContentImg {
      .workItemImg {
        width: calc((100% / 3) - 2rem);
      }
    }
  }
}

@media (max-width: 1023px) {
  .workText {
    width: 100%;
    margin-right: 0;
    .title {
      font-size: 30px;
      text-align: center;
    }
  }
  .workContainer {
    .workContent {
      .workItem {
        width: calc((100% / 2) - 2rem);
      }
    }
    .workContentImg {
      .workItemImg {
        width: calc((100% / 2) - 2rem);
      }
    }
  }
}

@media (max-width: 600px) {
  .workContainer {
    .workContent {
      iframe {
        height: 230px !important;
      }
      width: 90%;
      .workItem {
        &:hover {
          transform: scale(1);
        }
        width: 100%;
        .item {
          .titleItem {
            top: 0;
          }
        }
      }
    }
    .workContentImg {
      width: 90%;
      .workItemImg {
        &:hover {
          transform: scale(1);
        }
        width: 100%;
        height: 450px;
        .item {
          .titleItem {
            top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .workContainer {
    .workContent {
      iframe {
        height: 200px !important;
      }
    }
  }
}

@media (max-width: 361px) {
  .workContainer {
    .workContent {
      iframe {
        height: 180px !important;
      }
    }
  }
}
