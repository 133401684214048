.webgl-content {
  width: 100% !important;
  position: relative !important;
  transform: translate(0) !important;
  top: auto !important;
  left: auto !important;
  #unityContainer,
  .footer {
    width: 100% !important;
    max-width: 960px !important;
    margin: 1rem auto 0 auto !important;
  }
}

.gameContent {
  margin: 0 auto;
  margin-top: 4rem;
  width: 100%;
  max-width: 960px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 3rem;
  .gameContainer,
  .gameExplication {
    p {
      color: white;
    }
  }
  .gameContainer {
    .title {
      background: rgba(0, 0, 0, 0.8);
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }
    .text {
      margin-bottom: 0.4rem;
    }
  }
  .gameExplication {
    .title {
      background: rgba(0, 0, 0, 0.8);
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    p {
      margin-bottom: 0.4rem;
    }
    span {
      background-image: url("../../assets/fs.png");
      width: 32px;
      height: 32px;
      display: inline-block;
      position: relative;
      background-size: 32px;
      top: 6px;
      margin: 0 4px;
    }
  }
  .gameExamplesImg {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      width: calc((100% / 2) - 0.5rem);
      margin-bottom: 1rem;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .webgl-content {
    .footer {
      width: 80% !important;
    }
  }
  .gameContent {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .gameContent {
    .gameExamplesImg {
      a {
        width: 100%;
      }
    }
  }
}
