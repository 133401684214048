.content {
  width: 100%;
}

.headerContent {
  position: relative;
  padding: 16px 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
}

.Logo {
  cursor: pointer;
  color: white;
  font-size: 48px;
  text-decoration: none;
  text-shadow: 1px 1px 4px black;
  position: relative;
  z-index: 1;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .linkItem {
    margin: 8px 0;
    color: white;
    text-decoration: none;
    font-size: 24px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    &.active {
      color: #333;
      &::after {
        content: "";
        position: absolute;
        background: #36dfb7;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0) skew(25deg);
        width: 180%;
        height: 100%;
        z-index: -1;
      }
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}

.menuFixed {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  top: -100%;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 10;
}

.activeMenu {
  top: 0;
}

#menuToggle {
  display: block;
  z-index: 11;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

#menuToggle input {
  display: block;
  width: 48px;
  height: 34px;
  left: -8px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 30px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #cdcdcd;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(45deg) translate(1px, 1px);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(1) {
  transform: rotate(-45deg) translate(-2px, -3px);
}
